import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function BabyIDontKnow() {
  return (
    <Layout>
      <Helmet title="Baby I Don't Know | Michael Weslander" />

      <h1>Baby I Don't Know</h1>

      <h2>Michael Weslander</h2>

      <h4>Key: Bm</h4>

      <h4>Tempo: 130</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>Bm</Ch>Oh you think I'm crazy don't you
          </p>
          <p>Walking down this road on my own</p>
          <p>Lover you should know by now</p>
          <p>That I'm a man, who needs some time alone</p>
        </Verse>
        <Chorus>
          <p>No, I'm not trying to say</p>
          <p>That I don't want you by my side</p>
          <p>I just need a little space to breathe</p>
          <p>Just a little time</p>
        </Chorus>
        <Verse>
          <p>Baby, I don't know</p>
          <p>What I can do or say to ease your worried mind</p>
          <p>Is this because I only picked up once when you called me</p>
          <p>Maybe six or seven times</p>
        </Verse>
        <Chorus>
          <p>No, I'm not trying to say</p>
          <p>That I don't want you by my side</p>
          <p>I just need a little space to breathe</p>
          <p>Just a little time</p>
        </Chorus>
        <Bridge>
          <p>Baby I don't know</p>
          <p>Baby I don't know</p>
          <p>Baby I don't know</p>
          <p>Baby I don't know</p>
        </Bridge>
        <Bridge>
          <p>Bm - Bm/G# - F#m - G#m</p>
        </Bridge>
        <Chorus>
          <p>No, I'm not trying to say</p>
          <p>That I don't want you by my side</p>
          <p>I just need a little space to breathe</p>
          <p>Just a little time</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}